/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #DB5B1C;
$default-primary-dark-color: #000;
$default-primary-light-color: #E92328;
$default-secondary-base-color: #B45310;
$default-secondary-dark-color: #161D2A;
$default-secondary-light-color: #AF1B1F;
//$font-muli: 'Muli', sans-serif;
$font-standard-size: 62.5%;

// Colors
$grey1: #5F646B;
$grey2: #F2F2F2;
$grey3: #161D2A;
$grey4: #8A909B;
$grey5: #B1B1B1;
$grey6: #C1C4C9;
$grey7: #C4C6C9;
$grey8: #96999E;
$grey9: #E3E3E3;
$grey10: #E8E9EB;
$grey11: #FAFBFC;
$grey12: #19202E;
$grey13: #E1E2E4;
$grey14: #DFE4EF;
$grey15: #F9F9F9;
$grey16: #B8BECC;
$grey17: #20293A;
$grey18: #3F4246;
$grey19: #ECEFEF;
$grey20: #E1E1E1;
$grey21: #AAAAAA;
$grey22: #DADCE3;
$grey24: #DBDBDB;
$grey25: #ebebeb;

$dark2: #2E332F;

$blue1: #E3EAF6;
$blue2: #F2F5FA;

$red2: #CC2121;

$green1: #4A9539;
$green2: #0b3b00;
$green3: #e6f6e3;
$green4: #1F662D;
$green5: #334035;
$green7: #049D02;

$orange1: #FFF0E6;
$orange2: #DB5B1C;

$peace: #FFE7D6;

$yellow1: #F2B716;
$yellow2: #FFFAF7;

// Layout
$layout__max-width: 1344px;

// Box Shadow
$box-shadow1: 0px 1px 5px rgba(0, 0, 0, 0.1);
$box-shadow2: 0px 3px 6px rgba(0, 0, 0, 0.2);
$box-shadow3: 0px 3px 10px rgba(0, 0, 0, 0.1);
$box-shadow4: 0px -3px 20px rgba(0, 0, 0, 0.05);
$box-shadow5: 0px 1px 6px rgba(0, 0, 0, 0.16);
$box-shadow6: 0px 2px 5px rgba(0, 0, 0, 0.05);

// Animation
$transition1: all .5s;

// Icons
$icomoon-font-family: "icomoon-font-family" !default;

// Typography
$font-nunito-sans: 'Nunito Sans', sans-serif;
$base-font-family: $font-nunito-sans;

$font-weight-black: 900;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 400;

$letter-spacing-xl: 1.5px;
$letter-spacing-l: 0.88px;
$letter-spacing-m4: 0.42px;
$letter-spacing-m3: 0.5px;
$letter-spacing-m2: 0.38px;
$letter-spacing-m: 0.35px;
$letter-spacing-s: 0.7px;
$letter-spacing-s2: 0.5px;
$letter-spacing-s3: 0.3px;

$h1-font-color: $default-primary-dark-color;
$h1-font-weight: $font-weight-black;
$h1-letter-spacing: $letter-spacing-xl;
$h1-font-size-desktop: 6rem;
$h1-font-size-mobile: 35px;
$h1-font-line-height-desktop: 82px;
$h1-font-line-height-mobile: 47px;

$h2-font-color: $default-primary-dark-color;
$h2-font-weight: $font-weight-black;
$h2-letter-spacing: $letter-spacing-l;
$h2-font-size-desktop: 35px;
$h2-font-size-mobile: 30px;
$h2-font-line-height-desktop: 47px;
$h2-font-line-height-mobile: 41px;

$h3-font-color: $default-primary-dark-color;
$h3-font-weight: $font-weight-black;
$h3-letter-spacing: $letter-spacing-l;
$h3-font-size-desktop: 30px;
$h3-font-size-mobile: 28px;
$h3-font-line-height-desktop: 41px;
$h3-font-line-height-mobile: 38px;

$h4-font-color: $default-primary-dark-color;
$h4-font-weight: $font-weight-black;
$h4-letter-spacing: $letter-spacing-s;
$h4-font-size-desktop: 28px;
$h4-font-size-mobile: 20px;
$h4-font-line-height-desktop: 38px;
$h4-font-line-height-mobile: 27px;

$h5-font-color: $default-primary-dark-color;
$h5-font-weight: $font-weight-black;
$h5-letter-spacing: $letter-spacing-s;
$h5-font-size-desktop: 24px;
$h5-font-size-mobile: 18px;
$h5-font-line-height-desktop: 32px;
$h5-font-line-height-mobile: 24px;

$h6-font-color: $default-primary-dark-color;
$h6-font-weight: $font-weight-black;
$h6-letter-spacing: $letter-spacing-s;
$h6-font-size-desktop: 20px;
$h6-font-size-mobile: 14px;
$h6-font-line-height-desktop: 27px;
$h6-font-line-height-mobile: 19px;

$subtitle-primary-font-color: $default-primary-dark-color;
$subtitle-primary-font-size: 17px;
$subtitle-primary-font-line-height: 23px;
$subtitle-primary-font-weight: $font-weight-black;
$subtitle-primary-letter-spacing: $letter-spacing-m4;
$subtitle-primary-margin: 0 0 20px;

$subtitle-secondary-font-color: $default-primary-dark-color;
$subtitle-secondary-font-size: 15px;
$subtitle-secondary-font-line-height: 20px;
$subtitle-secondary-font-weight: $font-weight-black;
$subtitle-secondary-letter-spacing: $letter-spacing-m2;
$subtitle-secondary-margin: 0 0 20px;

$paragraph-font-family: $base-font-family;
$paragraph-font-color: var(--body-paragraph-color);
$paragraph-font-weight: $font-weight-regular;
$paragraph-letter-spacing: $letter-spacing-m;
$paragraph-font-size-desktop: 14px;
$paragraph-font-size-mobile: $paragraph-font-size-desktop;
$paragraph-font-line-height-desktop: 25px;
$paragraph-font-line-height-mobile: $paragraph-font-line-height-desktop;
