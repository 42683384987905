/**
 * KemanaPWA - Progressive Web App for Magento
 *
 * Copyright © 2021 Kemana, Inc. All rights reserved.
 * @author Ashan Gunawardena <agunawardena@kemana.com>
 * http://www.kemana.com
 *
 */

@mixin desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

// should be removed in future releases
// kept as legacy support
@mixin before-desktop {
    @include mobile {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 811px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        @content;
    }
}

// should be removed in future releases
// kept as legacy support
@mixin after-mobile {
    @include desktop {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

// Custom Breakpoints
@mixin before-desktop-l {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin desktop-l {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin desktop-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin desktop-xxl {
    @media (min-width: 1366px) {
        @content;
    }
}

// Printing media query
@mixin print-only {
    @media print {
        @content;
    }

    @at-root.body.is-pdf-mode #{&} {
        @content;
    }
}

// Touch devices only
@mixin touchdevice {
    @media (hover: none) and (pointer: coarse) {
        @content;
    }
}
